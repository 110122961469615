import React, { useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import SEO from "layout/SEO"
import Container from "layout/Container"

export default () => {
  useEffect(() => {
    navigate("/")
  }, [])

  return (
    <Layout>
      <SEO title="Novo Nordisk Patient Consent Form" />
      <Container isCentered>
        <h2>Novo Nordisk Patient Consent Form</h2>
        <hr />
        <div className="content is-medium">
          <p>
            I have been prescribed with Liraglutide (Saxenda<small>®</small>) by
            my doctor and been provided professional advice on the diagnosis and
            treatment of my obesity. I would like, however, to know more about
            the medication and get sufficient support throughout my treatment
            journey. Therefore, I am voluntarily joining the Early Experience
            Program (“Program”) to help me manage my medical condition well.
            This Program and my participation in this Program were clearly and
            thoroughly explained to me by my doctor and I understand that
            joining this Program may help me to achieve better health outcomes.
          </p>
          <br />
          <p>I understand that:</p>
          <ol>
            <li>
              MedGrocer is the authorized representative of Novo Nordisk
              Pharmaceutical (Philippines) Inc. (“Novo Nordisk Philippines”) in
              the implementation of the Program.{" "}
            </li>
            <li>
              MedGrocer’s Health Coach will provide more information on the
              mechanics and current benefits of the Program such as education,
              access, and safety support throughout my stay with the Program.{" "}
            </li>
            <li>
              The Health Coach may collect and process certain personal
              information for my enjoyment of the benefits of the Program and
              know that any information relayed about my Medical Condition, the
              importance of therapy, method of administration, precautions and
              special instructions is for informational purposes only and not
              meant to replace the professional advice of my doctor.{" "}
            </li>
            <li>
              All personal information obtained will be handled with full and
              strict confidentiality in compliance with the Republic Act No.
              10173 or the “Data Privacy Act of 2012” and that I have the right
              to access, correct, update and object to the processing of my
              personal information at any time by submitting a written request
              to the Data Protection Officer of MedGrocer and in appropriate
              cases, to lodge a complaint before the National Privacy
              Commission.{" "}
            </li>
            <li>
              The Health Coach will call and send me text messages to provide
              reminders, support for my Medical Condition and updates on the
              Program and I can call the Health Coach to clarify any concerns
              about the Program and to correct any personal information I have
              given.{" "}
            </li>
            <li>
              I will comply with all guidelines of the Program to enjoy its
              benefits; otherwise, my enrollment in the Program may be
              terminated.
            </li>
            <li>
              Novo Nordisk Philippines reserves the right to terminate any of
              the benefits available under the Program or the Program itself at
              any time without prior notice.{" "}
            </li>
            <li>
              Novo Nordisk Philippines and MedGrocer are obliged to collect
              details of any adverse events or product quality complaints that I
              may experience with Liraglutide (Saxenda®) during the conduct of
              the Program and throughout my medication. Should an adverse event
              or product complaint be identified, I agree that Novo Nordisk
              Philippines and/or MedGrocer will collect this information from
              either me and/or my doctor for safety reporting purposes only.{" "}
            </li>
          </ol>
          <p>
            I hereby represent and warrant that all personal information that I
            have provided for/will provide in connection with my application for
            this Program is correct, accurate and true. I confirm that my
            participation in this Program is voluntary and I am free to withdraw
            at any time based on my own decision or as recommended by my doctor
            and as such I will inform the Health Coach of my withdrawal by
            emailing{" "}
            <a
              href="mailto:saxenda@medgrocer.com"
              className="has-text-weight-bold has-text-primary"
            >
              saxenda@medgrocer.com
            </a>
            .
          </p>
        </div>
      </Container>
    </Layout>
  )
}
